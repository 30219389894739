import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { EventManager } from '../service/event-manager.service';
import { PrincipalService } from './principal.service';

@Injectable({ providedIn: 'root' })
export class UserRouteAccessService implements CanActivate {
    constructor(
        private router: Router,
        private eventManager: EventManager,
        private principal: PrincipalService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const authorities = route.data.authorities;
        // We need to call the checkLogin / and so the principal.identity() function, to ensure,
        // that the client has a principal too, if they already logged in by the server.
        // This could happen on a page refresh.
        return this.checkLogin(authorities, state.url);
    }

    checkLogin(authorities: string[], url: string): boolean | Observable<boolean> {
        // const principal = this.principal;
        const isAuthenticated = this.principal.isAuthenticated;
        console.log('navigated-url', url);
        if (!isAuthenticated) {
            this.eventManager.broadcast({
                name: 'show-login',
                content: {},
            });
            return true;
        }

        if (!authorities || authorities.length == 0) {
            return true;
        }

        this.router.navigate(['accessdenied']).then(() => {
            // only show the login dialog, if the user hasn't logged in yet
            if (!isAuthenticated) {
                this.eventManager.broadcast({
                    name: 'show-login',
                    content: {},
                });
            }
        });
        // refresh page
        console.log('RESULT', this.principal._loginCompleted());
        return isAuthenticated && this.principal._loginCompleted();
    }
}
