import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RedirectCommand, Router, RouterStateSnapshot } from '@angular/router';
import { PrincipalService } from '@ms/core';
import { EventManager } from '@ms/core/service/event-manager.service';
import { environment } from './../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private eventManager: EventManager,
        private principal: PrincipalService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log('URL', state.url);
        const { isAuthenticated } = this.principal;
        if (!this.principal.isAuthenticated) {
            this.eventManager.broadcast({
                name: 'show-login',
                content: {},
            });
            return false;
        } else {
            console.log('ROUTE-DATA', route.data);
            this.eventManager.broadcast({
                name: 'change-page-view',
                data: { fullPage: route.data?.fullPage ?? false },
            });
            const permissionId: string = route.data ? route.data.permissionId : undefined;
            const permissionIds: string[] = route.data ? route.data.permissionIds : undefined;
            const roles: string[] = route.data ? route.data.roles : undefined;
            if (!environment.development) {
                if (permissionId) {
                    const hasPermission = this.principal.hasPermission(permissionId);
                    //console.log('User has permission', hasPermission);
                    if (!hasPermission) {
                        const targetOfCurrentNavigation = this.router.getCurrentNavigation()?.finalUrl;
                        const redirect = this.router.parseUrl('/permission-error');
                        // Redirect to /401 internally but display the original URL in the browser's address bar
                        return new RedirectCommand(redirect, {
                            browserUrl: targetOfCurrentNavigation,
                        });
                        //this.router.navigate(['/permission-error', { url: state.url }]);
                        //return false;
                    }
                    return true;
                } else if (permissionIds?.length > 0) {
                    let hasPermission = false;
                    permissionIds.forEach(permissionId => {
                        const hasPermissionById: boolean = this.principal.hasPermission(permissionId);
                        if (hasPermissionById) {
                            hasPermission = true;
                        }
                    });

                    if (!hasPermission) {
                        const targetOfCurrentNavigation = this.router.getCurrentNavigation()?.finalUrl;
                        const redirect = this.router.parseUrl('/permission-error');
                        // Redirect to /401 internally but display the original URL in the browser's address bar
                        return new RedirectCommand(redirect, {
                            browserUrl: targetOfCurrentNavigation,
                        });
                        //this.router.navigate(['/permission-error', { url: state.url }]);
                        //return false;
                    }
                    return true;
                }
                if (roles?.length > 0) {
                    return this.principal.hasAnyAuthorityDirect(roles);
                }
            }
        }
        return isAuthenticated;
    }
}
